<template>
  <div>
    <v-card class="login__container mx-auto">
      <v-card-text>
        <div class="container text-center mt-8">
          <img class="logo" :src="logoSvg" :alt="logoAlt" />
        </div>
        <h1 class="login__info-text semibold-font ma-1 ma-lg-16 mt-lg-8" v-html="$t('running_app')"></h1>
        <div class="container">
          <div class="container row justify-center pl-1">
            <p class="semibold-font">{{ $t('click_button') }}</p>
            <div class="full-width" v-if="detectedPlatform !== 'unknown' && !isRedirecting">
              <p class="semibold-font">{{ $t('download_app') }} {{ platformName }}</p>
            </div>
          </div>
          <div class="container row">
            <div class="text-center full-width">
              <button class="main-button-gradient mt-0 mr-0" @click="prepareDataForClipboard()">
                <span class="semibold-font">{{ $t('run_app') }}</span>
              </button>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import { getIsExpoApp } from 'cuf-expo-bridge'

const utmParamsStorageKey = 'trackingUtm'
const googlePlayLink = 'https://play.google.com/store/apps/details?id=io.cufprod.android&hl=pl'
const appleStoreLink = 'https://apps.apple.com/pl/app/euro-tax/id6474239182?l=pl&platform=iphone'

/** @see https://stackoverflow.com/a/21742107 */
const getPlatform = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

const copyToClipboard = async (text) => {
  if (window.location.protocol === 'https:') {
    return navigator.clipboard.writeText(text)
  }
  const textArea = document.createElement('textarea')
  textArea.value = text
  // Ensure the textarea is not visible
  textArea.style.position = 'absolute'
  textArea.style.left = '-9999px'
  document.body.appendChild(textArea)
  textArea.select()
  textArea.setSelectionRange(0, 99999) // For iOS devices
  document.execCommand('copy')
  document.body.removeChild(textArea)
}

const redirectToStore = () => {
  const detectedPlatform = getPlatform()
  if (detectedPlatform === 'Android') {
    window.location.href = googlePlayLink
  } else if (detectedPlatform === 'iOS') {
    window.location.href = appleStoreLink
  }
}

export default {
  name: 'DownloadApp',
  data: function () {
    return {
      isRedirecting: true,
      googlePlayLink: 'https://play.google.com/store/apps/details?id=io.cufprod.android&hl=pl',
      appleStoreLink: 'https://apps.apple.com/pl/app/euro-tax/id6474239182?l=pl&platform=iphone'
    }
  },
  computed: {
    detectedPlatform () {
      return getPlatform()
    },

    platformName () {
      const detectedPlatform = getPlatform()
      if (detectedPlatform === 'Android') {
        return this.$t('play_store')
      } else if (detectedPlatform === 'iOS') {
        return 'App Store'
      } else {
        return ''
      }
    },

    logoSvg () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return require('@/assets/euro-tax.ro.svg')
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return require('@/assets/euro-tax.bg.svg')
      } else if (location.host.includes('.euro-tax.pl')) {
        if (this.$i18n.locale === 'ro') {
          return require('@/assets/euro-tax.ro.svg')
        } else if (this.$i18n.locale === 'bg') {
          return require('@/assets/euro-tax.bg.svg')
        } else {
          return require('@/assets/euro-tax.pl.svg')
        }
      } else {
        return require('@/assets/cuf-logo.svg')
      }
    },
    logoAlt () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return ''
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return ''
      } else if (location.host.includes('.euro-tax.pl')) {
        return 'Panel Klienta Euro-tax”'
      } else {
        return 'Panel Klienta CUF'
      }
    }
  },

  mounted () {
    if (getIsExpoApp()) {
      this.$router.push('/')
    }
  },

  methods: {
    prepareDataForClipboard () {
      const queryParams = new URLSearchParams(window.location.search)
      const clipboardObj = {
        [utmParamsStorageKey]: Object.fromEntries(queryParams)
      }
      const encoded = JSON.stringify(clipboardObj)
      copyToClipboard(encoded).then(redirectToStore)
    }
  }

}
</script>

<style lang="scss">
  .login__container {
    width: 574px;
    min-height: 586px;
    border-radius: 20px !important;
    opacity: 1;
    background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, #F57921, #D20F6E) border-box !important;
    border: 2px solid transparent !important;
  }

  .login__info-text {
    line-height: 1.55;
    text-align: left;
  }

  .full-width {
    width: 100%
  }
</style>
